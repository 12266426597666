
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Noto Sans KR', sans-serif;
}
/* App.js */
.container {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 100vh; */
  background: linear-gradient(to right bottom, #f8edeb, #fdf4ec);
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-wrapper {
  background-color: #5d6770;
  min-width: 450px;
  min-height: 650px;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 3px 6px 40px #000;
  margin-bottom: 10px;
}
.title h1 {
  color: #fffffc;
  text-align: center;
  margin: 30px 0;
}
.task-input {
  outline: none;
  width: calc(100% - 80px);
  min-height: 50px;
  padding: 15px;
  margin-right: 25px;
  font-size: 20px;
  color: rgb(105, 99, 99);
  background-color: #fffffc;
  border: 1px solid #f8edeb;
  border-radius: 10px;
  resize: none;
}
.task-textarea {
  outline: none;
  width: calc(100% - 80px);
  min-height: 100px;
  padding: 15px;
  margin-right: 25px;
  margin-top: 20px;
  font-size: 20px;
  color: rgb(105, 99, 99);
  background-color: #fffffc;
  border: 1px solid #f8edeb;
  border-radius: 10px;
  resize: none;
}

.task-select {
  margin-top: 20px;
}
.button-add {
  width: 70px;
  padding: 15px 15px;
  font-size: 20px;
  border-radius: 10px;
  border: 0;
  background-color: #f8edeb;
  cursor: pointer;
}
.list-item {
  display: flex;
  margin: 20px 0;
  border: 1px solid #fffffc;
  border-radius: 10px;
  padding: 10px;
}
.button-complete,
.button-delete,
.button-edit {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  font-size: 28px;
  border-radius: 50%;
}
.button-complete {
  color: #e97171;
  margin-right: 10px;
}
.button-edit {
  color: #f8f1b0;
  margin-right: 10px;
}
.button-delete {
  color: rgb(194, 250, 179);
}
.complete {
  text-decoration-style: solid;
  text-decoration-line: line-through;
  text-decoration-color: #fffffc;
  opacity: 0.6;

  display: flex;
  margin: 20px 0;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
}
.list {
  width: 220px;
  background-color: #5d6770;
  border: none;
  color: #fffffc;
  font-size: 20px;
  padding-left: 10px;
  margin-right: 15px;
}
.list:focus {
  background-color: #fcd5ce;
  border-radius: 10px;
}
input:focus,
button:focus {
  outline: none;
}
.table-td-img {
  max-width : 100px;
  max-height: 100px;
  object-fit: contain;
}